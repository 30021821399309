<template>
    <fieldset class="fieldsetc">
        <legend class="legendc">预算额度使用情况（行政开支不计入已用额度）</legend>
        <el-descriptions
            class="margin-top"
            :column="3"
            :labelStyle="{'width':'120px','text-align':'center'}"
            :contentStyle="{'text-align': 'center'}"
            border
        >
            <el-descriptions-item>
                <template slot="label">
                    <span>可用总预算</span>
                </template>
                <span style="color: #2828FF;font-weight: bold;">{{budget_obj.all_quota | fomatFloatMoney}}</span>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>已用总额度</span>
                </template>
                <span v-if="budget_obj.all_quota>budget_obj.use_quota" style="color: #00BB00;font-weight: bold;">{{budget_obj.use_quota | fomatFloatMoney}}</span>
                <span v-else style="color: #EA0000;font-weight: bold;">{{budget_obj.use_quota | fomatFloatMoney}}</span>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>已用百分比</span>
                </template>
                <span v-if="budget_obj.all_quota>budget_obj.use_quota" style="color: #00BB00;font-weight: bold;">{{ budget_obj.per_quota + '%'}}</span>
                <span v-else style="color: #EA0000;font-weight: bold;">{{ budget_obj.per_quota + '%'}}</span>
            </el-descriptions-item>
            <el-descriptions-item>
            <template slot="label">
                <span>招待类开支</span>
            </template>
            <span>{{budget_obj.entertain_quota | fomatFloatMoney}}</span>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>差旅类开支</span>
                </template>
                <span>{{budget_obj.travel_quota | fomatFloatMoney}}</span>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>行政类开支</span>
                </template>
                <!-- <span>{{budget_obj.other_quota | fomatFloatMoney}}</span> -->
                <span>-</span>
            </el-descriptions-item>
        </el-descriptions>
    </fieldset>
</template>

<script>
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'CommonBudgetComponent',
    data(){
        return {}
    },
    computed:{},
    methods:{},
    filters:{
        fomatFloatMoney(data) {
            return data ? '￥ ' + thousandBitSeparator(fomatFloat(data)) : '￥ 0.00'
        }
    },
    props:{
        budget_obj: {
            type: Object,
            default() {
                return {
                    all_quota: 0,
                    use_quota: 0,
                    per_quota: 0,
                    entertain_quota: 0,
                    travel_quota: 0,
                    other_quota: 0
                }
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
</style>