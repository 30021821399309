<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="70%"
            :center="true"
            top="6vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog('CarUseEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="CarUse.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="CarUse.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="CarUse.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="CarUse.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(CarUse.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="CarUse"
                    :rules="rules"
                    ref="CarUseEditForm"
                    label-width="120px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="申请部门" prop="department_id">
                                        <el-cascader
                                            v-model="CarUse.department_id"
                                            :options="enterprise_department"
                                            :props="{ expandTrigger: 'hover' }"
                                            clearable
                                            style="width: 100%"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item  label="申请人" prop="staff_id">
                                        <el-select
                                            v-model="CarUse.staff_id"
                                            filterable
                                            clearable
                                            placeholder="选择申请人"
                                            class="staff_idc"
                                        >
                                            <el-option
                                                v-for="item in AllStaff"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">使用信息</legend>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="使用时间" prop="start_time">
                                        <el-date-picker
                                            v-model="CarUse.start_time"
                                            align="right"
                                            type="datetime"
                                            placeholder="使用时间"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            class="start_timec"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="预计归还时间" prop="end_time">
                                        <el-date-picker
                                            v-model="CarUse.end_time"
                                            align="right"
                                            type="datetime"
                                            placeholder="预计归还时间"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            class="end_timec"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="申请原因" prop="content">
                                <el-input type="text" v-model="CarUse.content"></el-input>
                            </el-form-item>
                            <el-form-item label="其它说明" prop="note">
                                <el-input type="textarea" :rows="5" v-model="CarUse.note" placeholder="如车牌或可能产生的车辆相关费用等"></el-input>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="CarUse.other"
                            upload_url="car_use/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请部门</span>
                                    </template>
                                    <span>{{CarUse.enterprise_name ? CarUse.enterprise_name + '/' + CarUse.department_name : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{CarUse.staff_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">使用信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>使用时间</span>
                                    </template>
                                    <span>{{CarUse.start_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <template slot="label">
                                        <span>预计归还时间</span>
                                    </template>
                                    <span>{{CarUse.end_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>申请原因</span>
                                    </template>
                                    <span>{{CarUse.content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>其它说明</span>
                                    </template>
                                    <span>{{CarUse.note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="CarUse.other.length"
                            ref="upload"
                            :ffileList="CarUse.other"
                            upload_url="car_use/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="CarUse/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval('CarUseEditForm')"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="CarUseWithdraw('CarUseEditForm')"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="CarUseDel('CarUseEditForm')"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog('CarUseEditForm')"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('CarUseEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('CarUseEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('CarUseEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('CarUseEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('CarUseEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="CarUse.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="CarUseApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <car-use-print-component
                    :show_print="show_print"
                    :data="CarUse"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </car-use-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import CarUsePrintComponent from './CarUsePrintComponent'
import CommonBudgetComponent from '@/components/common/CommonBudgetComponent'

import {
    car_use_add_request,
    car_use_del_request,
    car_use_edit_request,
    car_use_to_approval_request,
    car_use_sel_approval_request,
    car_use_approval_request,
    car_use_withdraw_request,
    car_use_add_file_request
} from '@/network/hrm/CarUse.js'
import { staff_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'CarUseEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            CarUse: {
                start_time: '',
                end_time: '',
                content: '',
                note: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                other: [],
                print: []
            },
            rules: {
                start_time: [
                    {
                        required: true,
                        message: '请选择使用时间',
                        trigger: 'blur',
                    },
                ],
                end_time: [
                    {
                        required: true,
                        message: '请选择预计归还时间',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请填写申请原因',
                        trigger: 'blur',
                    },
                ],
                department_id: [
                    {
                        required: true,
                        message: '请选择申请部门',
                        trigger: 'blur',
                    },
                ],
                staff_id: [
                    {
                        required: true,
                        message: '请选择申请人',
                        trigger: 'blur',
                    },
                ],
            },
            ApprovalData: [],
            CarUseApprovalRequest:car_use_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.CarUse.name ? '公务车使用申请：' + this.CarUse.name : '公务车使用申请详情'
            } else if (this.show_type === 1) {
                return '公务车使用申请'
            } else {
                return '修改公务车使用申请'
            }
        },
    },
    methods: {
        closeDialog(formName) {
            this.CarUse = {
                start_time: '',
                end_time: '',
                content: '',
                note: '',
                department_id: [this.$store.state.user.enterprise_id,this.$store.state.user.department_id],
                staff_id: this.$store.state.user.id,
                other: [],
                print: []
            }
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CarUseInsert(formName)
            })
        },
        CarUseInsert(formName) {
            this.login_loading = true
            let d = this.CarUse
            d.department_id = d.department_id[d.department_id.length - 1]
            d.other = this.$refs.upload.fileList
            car_use_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CarUseEdit(formName)
            })
        },
        CarUseEdit(formName) {
            this.login_loading = true
            this.CarUse.id = this.id
            this.CarUse.department_id = this.CarUse.department_id[this.CarUse.department_id.length - 1]
            this.CarUse.other = this.$refs.upload.fileList
            car_use_edit_request(this.CarUse)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.CarUse.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CarUseDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    car_use_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    car_use_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            car_use_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CarUseWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    car_use_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            car_use_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            car_use_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        car_use_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllStaff: {
            type: Array,
            default() {
                return []
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        CarUsePrintComponent,
        CommonBudgetComponent
    },
    watch: {
        car_use_details_data(newVal) {
            this.CarUse = newVal
            this.CarUse.id = this.id
        },
    },
}
</script>

<style>
.input_number,.staff_idc,.typec {
    width: 100%;
}
.el-date-editor.el-input {
    width: 100%;
}
</style>